.ProductWrapper {
  padding: 15px 10px 0 10px;
}
.Title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #00b587;
}

/* category card  */
.ProductCard {
  border-radius: 16px !important;
  margin: 10px 0 !important;
  box-shadow: 0px 0px 12px -1px rgba(34, 60, 80, 0.2) !important;
}
.CardTable {
  margin-top: 10px;
}
.CardItemName {
  font-size: 10px;
  padding: 5px 10px 5px 0;
}
.CardItemValue {
  font-size: 13px;
  font-weight: bold;
}
/*popup*/
.PopupData {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
}

/* floatButton */
.FloaatButtonText {
  font-size: 25px;
  font-weight: 700;
}
